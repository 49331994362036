import React, { Component } from 'react';
import Img from 'react-image';

import ScrollToTopOnMount from '../../../../ScrollToTopOnMount';

import { getFirebase } from '../../../../firebase';

class ShoulderStrap01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            ready: false
        }
    }

    renderPrice() {
        const amount = String(this.state.data.price[0].amount.toFixed(2));
        let radixPos = amount.indexOf('.');
        let intValue = parseInt(amount);
        let decValue =amount.slice(radixPos + 1);
        return '$' + intValue + '.' + decValue;
    }

    componentDidMount() {
        // DATABASE
        getFirebase()
        .database()
        .ref("/accessories/2")
        .once("value")
        .then(snapshot => {
            let result = snapshot.val();
            console.log(result);
            this.setState({
                data: result,
                ready: true
            })
        })
    }

    render() {
        if (this.state.ready) {
            return(     
                <div id='theboxcontroller' className='columns is-multiline'>
                    <ScrollToTopOnMount />

                    <div className='column is-full is-half-tablet is-mobile'>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title.jpg","https://via.placeholder.com/1200x800"]} alt='CSTournamentSolo'/>
                    </div>

                    <div className='column is-full is-half-tablet is-mobile'>
                        <h3 className='title is-size-4-mobile'>{this.state.data.name}</h3>
                        <p className='subtitle'>{this.state.data.subtitle}</p>
                        <p className='price'>{this.renderPrice()}<br/></p>

                        <p className='is-size-6'>Shoulder strap is compatible with all bags.</p>
                    </div>

                    <div className='column is-10 is-offset-1 has-text-centered'>
                        <h3 className='title is-size-1-desktop'>Specs.</h3>
                    </div>
                    
                </div>
                
            );
        } else {
            return(
                <div>Shoulder Strap (01) for all bags.</div>
            )
        }
        
    }
}

export default ShoulderStrap01;