import React, { Component } from 'react';
import Img from 'react-image';

import ScrollToTopOnMount from '../../../../ScrollToTopOnMount';

import { getFirebase } from '../../../../firebase';

class TCGTravelerDuo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            ready: false
        }
    }

    renderPrice() {
        const amount = String(this.state.data.price[0].amount.toFixed(2));
        let radixPos = amount.indexOf('.');
        let intValue = parseInt(amount);
        let decValue =amount.slice(radixPos + 1);
        return '$' + intValue + '.' + decValue;
    }

    componentDidMount() {
        // DATABASE
        getFirebase()
        .database()
        .ref("/products/2")
        .once("value")
        .then(snapshot => {
            let result = snapshot.val();
            console.log(result);
            this.setState({
                data: result,
                ready: true
            })
        })
    }

    render() {
        if (this.state.ready) {
            return(     
                <div id='tcgtravelerduo' className='columns is-multiline'>
                    <ScrollToTopOnMount />
                    <div className='column is-full is-mobile hero-container'>
                        {/* HTML Text version */}
                        <div className='text-container has-text-centered'>
                            <h1 className='title is-size-1 is-size-4-mobile'>{this.state.data.name}</h1>
                            <p className='subtitle desc is-size-6'>{this.state.data.subtitle}</p>
                        </div>
                        <div className='img-container'>
                            {/* <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title.jpg", "./img/products/bags/placeholder/title.jpg"]} alt={this.state.data.key} /> */}
                            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" poster={"./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title-vid-pic.jpg"}>
                                <source src={"./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title-vid.mp4"} type="video/mp4"></source>
                            </video>
                        </div>
                        {this.state.data.isOnSale ?
                            <div className='price-container has-text-centered'>
                                <span className='price-sale-text'>{this.state.data.price[0].saleText}</span><br/>
                                <p className='price-text is-size-3'>${this.state.data.price[0].saleAmount}</p>
                                <span className='price-st'>${this.state.data.price[0].amount}</span>
                            </div>
                        :
                            <div className='price-container has-text-centered'>
                                {/* <span className='price-sale-text'>{this.state.data.price[0].saleText}</span><br/> */}
                                <p className='price-text normal is-size-3'>${this.state.data.price[0].amount}</p>
                                {/* <span className='price-sale-text'>{this.state.data.price[0].amount}</span> */}
                            </div>
                        }
                    </div>

                    <div className='column is-full is-mobile has-text-centered'>
                        <Img src={['./img/products/'+this.state.data.category+'/'+this.state.data.key+'/bagbanner.jpg', "./img/products/bags/placeholder/title.jpg"]} alt={this.state.data.key} />
                        <h3 className='title is-size-4-mobile'>Protect Your Game Cards</h3>
                        <p className='subtitle desc'>Your cards now resist physical damage.</p>
                        <h3 className='title is-size-4-mobile'>Center Divider Included</h3>
                        <p className='subtitle desc'>Organization at ease.</p>
                        <h3 className='title is-size-4-mobile'>Four Zipper Pockets</h3>
                        <p className='subtitle desc'>For even MORE organization.</p>
                        <h3 className='title is-size-4-mobile'>Lightweight &amp; Compact Design</h3>
                        <p className='subtitle desc'>Fits everywhere, goes anywhere.</p>
                        <h3 className='title is-size-4-mobile'>Premium Build Quality</h3>
                        <p className='subtitle desc'>Made-to-last with high-end materials.</p>
                    </div>

                    <div className='column is-full is-mobile'>
                        
                        <p>TCG Traveler Duo is a gaming carry bag made for trading card game players. With larger size and included center divider, Traveler Duo provides higher capacity and space for other gaming accessories on top of your card collection.</p>
                        <br />
                        
                    </div>

                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>Mobilize Your Fleet.</h3>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/set1.jpg"]} alt={this.state.data.key} />
                    </div>
                    <div className='column is-full is-mobile'>
                        <p>Your entire card collection in a single bag. Traveler Duo is 17.5 inches wide, which means it can hold A LOT of cards. It can easily store your whole game card collection and other accessories and bring them on the go. It also has 4 meshed pockets with zippers for storing smaller items such as tokens, dices, game chips and more. Bring your whole collection with ease to your next gaming event with Traveler Duo.</p>
                        <br />
                    </div>

                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>Double-decker For Capacity.</h3>
                        {/* <Img src={["https://via.placeholder.com/1200x800", "./img/products/"+this.state.data.category+"/"+this.state.data.key+"/set2.jpg",]} alt={this.state.data.key} /> */}
                        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" poster={"./img/products/"+this.state.data.category+"/"+this.state.data.key+"/divider-pic.jpg"}>
                            <source src={"./img/products/"+this.state.data.category+"/"+this.state.data.key+"/divider.mp4"} type="video/mp4"></source>
                        </video>
                    </div>
                    <div className='column is-full is-mobile'>
                        <p>Traveler Duo comes with a padded, flexible divider that can be easily moved and attached thanks to its velcro-based design. You can use it to organize and store gaming accessories or extra game cards you need to carry. If needed, you can remove it to have more space for larger items.
                        </p>
                        <br />
                        <div className='columns is-multiline'>
                            <div className='column is-half has-text-centered'>
                            <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/dividers.jpg","https://via.placeholder.com/720x480"]} alt='CSTournamentSolo'/>
                            <p className='is-size-6'>Divider is made with ABS plastic for durability and flexibility, and it can be easily removed thanks to its velcro-based design.</p>
                            </div>

                            <div className='column is-half has-text-centered'>
                            <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/pockets.jpg","https://via.placeholder.com/720x480"]} alt='CSTournamentSolo'/>
                            <p className='is-size-6'>Traveler Duo comes with 4 mesh pockets with zippers, making it easy to store small items and protect them from damage.</p>
                            </div>
                        </div>
                        <br />
                    </div>

                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>Not Your Average Game Bag.</h3>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/set3.jpg"]} alt={this.state.data.key}/>
                    </div>
                    <div className='column is-full is-mobile has-text-centered'>
                        <h3 className='title is-size-4-mobile'>1680D Ballistic Nylon</h3>
                        <p className='subtitle desc'>Weatherproof. Tear resistant. Military-grade.</p>
                        <h3 className='title is-size-4-mobile'>ABS Plastic Hard Shell Frames</h3>
                        <p className='subtitle desc'>Protects sharp impact from all sides.</p>
                        <h3 className='title is-size-4-mobile'>High Quality Double Stitching</h3>
                        <p className='subtitle desc'>Makes the bag stronger &amp; tougher.</p>
                        <h3 className='title is-size-4-mobile'>Inner Poly Foam Padding</h3>
                        <p className='subtitle desc'>Extra-extra-extra protection.</p>
                        <h3 className='title is-size-4-mobile'>Rubberized Handle &amp; Shoulder Strap</h3>
                        <p className='subtitle desc'>Reduces fatigue &amp; provides easy handling.</p>
                    </div>
                    <div className='column is-full is-mobile'>
                        <p>Serious player needs serious gear. Traveler Duo is made with high-quality materials that are far beyond in quality compared to other cheaply-made bags on the market. It is completely weatherproof and tear resistant thanks to 1680D Ballistic Nylon fabric, so your cards won't get wet easily from sudden rain. Our bags are carefully hand-made in our on-site factory, with double-stitching to ensure longevity. This is the bag that is made to travel with you at all times, no matter what.</p>
                        <br />
                    </div>

                    <div className='column is-10 is-offset-1 has-text-centered'>
                        <h3 className='title is-size-1-desktop'>Specs.</h3>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/spec.jpg","https://via.placeholder.com/600x400"]} alt={this.state.data.key}/>
                    </div>
                    <div className='column is-10 is-offset-1'>
                        <div className='columns is-variable is-6 is-multiline'>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Features</h3>
                                <li className='spec list'>TCG Traveler Duo Card Game Bag</li>
                                <li className='spec list'>4 Mesh Inner Pockets with Zippers</li>
                                <li className='spec list'>Shoulder Strap Included</li>
                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Card Compatibility</h3>
                                <li className='spec list'>Fits all standard size game cards</li>
                                <li className='spec list'>Card/Deck Size Up to 3" Wide and 4" Tall</li>
                                <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/card.png"]} alt='cardspec'/>
                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Bag Materials</h3>
                                <li className='spec list'>1680D Ballistic Nylon Outer Shell</li>
                                <li className='spec list'>210D Inner Lining</li>
                                <li className='spec list'>ABS Plastic Inner Frame</li>
                                <li className='spec list'>Poly Foam Cusioning</li>
                                <li className='spec list'>Rubberized Handle</li>
                                <li className='spec list'>Custom-designed Zippers</li>
                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Dimensions &amp; Weight</h3>
                                <li className='spec list'>Bag Dimensions: 17.5" W x 8.5" H x 4" D</li>
                                <li className='spec list'>Inner Dimensions: 16.5" W x 7" H x 3" D</li>
                                <li className='spec list'>Weight: 1 lbs</li>
                            </div>
                        </div>
                    </div>

                    <div className='column is-10 is-offset-1 has-text-centered'>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/color.jpg","https://via.placeholder.com/1280x540"]} alt='NSTournamentSpec'/>
                        <h3 className='title is-4 has-text-grey-lighter'>Available In <span className='black'>Black</span></h3>
                        <a class="button is-info has-text-weight-bold" href="https://www.mcycustomcase.com/shop?category=gaming">Buy Now</a>
                    </div>
                </div>
                
                
            );
        } else {
            return(
                <div>Loading...</div>
            )
        }
        
    }
}

export default TCGTravelerDuo;