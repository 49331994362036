import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
// import Img from 'react-image';

import * as ROUTES from '../../constants/routes.js';

import './Footer.css';

class Footer extends Component {
    render() {
        return(
            <div className='uc-footer'>
                <footer className="footer">
                    <div className="content">
                        <span className='has-text-weight-black'>UPPER<span className='red'>_</span>CASE GAMING </span><span className='has-text-family-campaign is-size-6'>by MCY Custom Case</span>
                        <nav>
                            <div className='category'>
                                <Link to={ROUTES.HOME} className='category-link'>Home</ Link>
                            </div>
                            <div className='category'>
                                <Link to={ROUTES.ABOUT} id='#about' className='category-link'>About Us</ Link>
                            </div>
                            <div className='category'>
                                <Link to={ROUTES.ABOUT} id='#bags' className='category-link'>Bags &amp; Cases</ Link>
                            </div>
                            <div className='category'>
                                <Link to={ROUTES.ACCESSORIES} id='#accessories' className='category-link'>Accessories</ Link>
                            </div>
                            <div className='category'>
                                <Link to={ROUTES.CUSTOM} id='#custom' className='category-link'>Custom Cases</ Link>
                            </div>
                            <div className='category'>
                                <Link to={ROUTES.CONTACT} id='#inquiries' className='category-link'>Inquiries &amp; Contact</ Link>
                            </div>
                        </nav>
                        <br />
                        <p className='is-size-6'>UPPER_CASE GAMING is a brand made by <a href="https://www.mcycustomcase.com">MCY Custom Case Corporation</a>.</p>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer