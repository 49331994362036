import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Img from 'react-image';

import * as ROUTES from '../../constants/routes.js';

import Navigation from '../../components/Navigation';
import NavigationBar from '../../components/NavigationBar';

import About from '../../components/Content/About';
import Bags from '../../components/Content/Bags';
import Accessories from '../../components/Content/Accessories';
import Custom from '../../components/Content/Custom';
import Inquiries from '../../components/Content/Inquiries';

import Footer from '../../components/Footer'

import './Home.css';

class Home extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        let ti = this.props.name ? this.props.name : "";
        document.title = ti + " :: UPPER_CASE GAMING";
    }


    render() {
        return(
            <main class="uc-main">
                <div className='is-hidden-desktop'>
                    <NavigationBar />
                </div>
                <div className='uc-side-background is-hidden-touch'></div>
                <div className='uc-container container'>
                    <div className='uc-duo'>
                        
                        <aside className='uc-side menu is-hidden-touch'>
                            <Navigation />
                        </aside>
                        <div className='uc-lead'>
                            <About />
                            <Bags />
                            <Accessories />
                            <Custom />
                            <Inquiries />
                            <Footer />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default Home;