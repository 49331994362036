import React, { Component } from 'react';
import Editor from '@toast-ui/editor';
import Img from 'react-image';

import '@toast-ui/editor/dist/toastui-editor.css';

import { getFirebase, generateDate } from "../../../firebase";

class EditTest extends Component {
    super(props) {
        this.state = {
            product: {
                key: "",
                name: "",
                tag: [],
                title: "",
                thumbnail: "",
                content: "",
                date: ""
            },
            posts: [],
            isLoading: true
        }
    }

    componentDidMount() {

        getFirebase()
            .database()
            .ref("/products")
            .once("value")
            .then(snapshot => {
                let product = {};
                let posts = [];
                let result = snapshot.val();
                for (let slug in result) {
                    posts.push(result[slug]);
                }
                if (result[0] !== undefined) {
                    product = {
                        name: result[0].name,
                        key: result[0].key,
                        tag: result[0].tag,
                        title: result[0].title,
                        thumbnail: result[0].thumbnail,
                        content: result[0].content,
                        date: result[0].date
                    }
                }
                console.log(product);
                this.setState({
                    product: product,
                    posts: posts,
                    isLoading: false
                })

                const editor = new Editor({
                    el: document.querySelector('#editor'),
                    height: '600px',
                    initialEditType: 'wysiwyg',
                    initialValue: product.content
                })

                const viewer = new Editor.factory({
                    el: document.querySelector('#viewer'),
                    viewer: true,
                    initialValue: product.content
                })
            })
        
    }

    saveDocument() {
        let content = Editor.getInstances()[0].getHtml();
        
        const date = generateDate();
        const newPost = {
            key: this.state.product.key,
            name: this.state.product.name,
            tag: this.state.product.tag,
            title: this.state.product.title,
            thumbnail: this.state.product.thumbnail,
            date: date.formatted,
            content: content
        }

        getFirebase()
            .database()
            .ref()
            .child('products/0')
            .set(newPost);
        
    }

    render() {
        return(
            <div className='columns is-multiline'>
                <div className='column is-full'> 
                    <h1 className='title'>Edit Product</h1>  
                    {this.state.product !== undefined ? <div className='mb-1'>
                        <h3 className='is-6'>Editing {this.state.product.name}</h3>
                        <p className='is-size-6'>key: {this.state.product.key}</p>

                    </div> : null}
                    <div id='editor' className='mb-4'></div>
                    <div class="buttons">
                        <button class="button is-info is-outlined has-text-weight-bold" onClick={this.saveDocument.bind(this)}>Save</button>
                        <button class="button is-danger is-outlined has-text-weight-bold">Cancel</button>
                    </div>
                </div>
                {this.state.product !== undefined ? 
                <div className='column is-full'>
                    <h1>View</h1>
                    <p>Name: {this.state.product.name}</p>
                    <p>key: {this.state.product.key}</p>
                    <p>tag: {this.state.product.tag.map(i => { return(i + ", ")})}</p>
                    <p>title: {this.state.product.title}</p>
                    <p>thumbnail: {this.state.product.thumbnail}</p>
                    <p>date: {this.state.product.date}</p>
                    
                </div>
                : null }
                <div className='column is-full'>
                    <div id='viewer'></div>
                </div>
            </div>
        )
    }
}

export default EditTest