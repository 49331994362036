import React, { Component } from 'react';
import Img from 'react-image';


class Inquiries extends Component {

    render() {
        return(
            <div id='inquiries' className='section'>
                <div className='container'>
                    <div className='columns is-multiline'>
                        <div className='column is-full mb-4'>
                        <h1 className='title'>Want To Feature Our Bags?</h1>
                        <p className='is-size-5 mb-1'>Are you a Content Creator looking for engaging content or giveaway opportunities? Want to review our bags? Seeking for sponsorship opportunities for your next gaming event? We can gladly assist you!</p>
                        <a class="button is-info has-text-weight-bold" href="mailto:info@mcycustomcase.com?subject=Sponsorship/Review/Giveaway%20Inquiry">Contact Us</a>  
                        {/* <button class="button is-info has-text-weight-bold">Inquire Us</button> */}
                        </div>

                        <div className='column is-full'>
                        <h1 className='title'>Questions?</h1>
                        <p className='is-size-5 mb-1'>Do you have general questions about our bags? Feel free to contact us!</p>
                        <a class="button is-info has-text-weight-bold" href="mailto:info@mcycustomcase.com?subject=General%20Inquiry">Ask Us</a>  
                        {/* <button class="button is-info has-text-weight-bold">Contact Us</button> */}
                        </div>
                    </div>
                    
                </div>
                
            </div>
        )
    }
}

export default Inquiries;