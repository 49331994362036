import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Img from 'react-image';

import {
    BrowserRouter as Router,
    Route,
  } from 'react-router-dom';

import * as ROUTES from '../../constants/routes.js';

import Navigation from '../../components/Navigation';
import NavigationBar from '../../components/NavigationBar';

import NSTournament from '../../components/Products/Bags/cstournamentduo';

import Footer from '../../components/Footer';

import Breadcrumb from '../../components/Breadcrumb';

import EditTest from '../../components/Content/EditTest/index.js';

class Edit extends Component {
    // constructor(props) {
    //     super(props);

    // }

    componentDidMount() {
        document.title = "UPPER_CASE GAMING";
        console.log(this.props.name);
    }

    render() {
        return(
                <main class="uc-main">
                    <div className='is-hidden-desktop'>
                        <NavigationBar />
                    </div>
                    <div className='uc-side-background is-hidden-touch'></div>
                    <div className='uc-container container'>
                        <div className='uc-duo'>
                            
                            <aside className='uc-side menu is-hidden-touch'>
                                <Navigation />
                            </aside>

                            <div className='uc-lead'>
                                
                                <div className='section'>
                                    <Breadcrumb 
                                        history={[
                                            {
                                                route: ROUTES.BAGS,
                                                name: "Bags"
                                            }
                                        ]}
                                        current={{
                                            route: this.props.location.pathname,
                                            name: this.props.name
                                        }}
                                    />
                                    <div className='container'>
                                        <Route path={ROUTES.EDITTEST} component={EditTest} />
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </main>
        )
    }
}

export default Edit;