import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import { getFirebase } from './firebase';

import Home from './pages/Home';
import Product from './pages/Product';

import * as ROUTES from './constants/routes';
import './App.css';

import Edit from './pages/Edit';




class App extends Component {
  constructor(props){
    super(props);

  }

  render() {
    return (
      <Router>
          {/* <ScrollToTop /> */}
          <Route exact path={ROUTES.LANDING} render={(props) => <Home {...props} name="Home" />} />
          <Route path={ROUTES.ABOUT} render={(props) => <Home {...props} name="About" />} />
          <Route path={ROUTES.HOME} render={(props) => <Home {...props} name="Home" />} />
          <Route path={ROUTES.BAGS} render={(props) => <Home {...props} name="Bags" />} />
          <Route path={ROUTES.ACCESSORIES} render={(props) => <Home {...props} name="Accessories" />} />
          <Route path={ROUTES.CUSTOM} render={(props) => <Home {...props} />} name="Custom" />
          <Route path={ROUTES.CONTACT} render={(props) => <Home {...props} />} name="Contact" />

          <Route path={ROUTES.CSTOURNAMENTDUO} render={(props) => <Product {...props} name="CS Tournament Duo" categoryRoute={ROUTES.BAGS} categoryName='Bags' />}/>
          <Route path={ROUTES.CSTOURNAMENTSOLO} render={(props) => <Product {...props} name="CS Tournament Solo" categoryRoute={ROUTES.BAGS} categoryName='Bags' />}/>
          <Route path={ROUTES.TCGTRAVELERSOLO} render={(props) => <Product {...props} name="TCG Traveler Solo" categoryRoute={ROUTES.BAGS} categoryName='Bags' />}/>
          <Route path={ROUTES.TCGTRAVELERDUO} render={(props) => <Product {...props} name="TCG Traveler Duo" categoryRoute={ROUTES.BAGS} categoryName='Bags' />}/>

          <Route path={ROUTES.SHOULDERSTRAP01} render={(props) => <Product {...props} name="Shoulder Strap (01)" categoryRoute={ROUTES.ACCESSORIES} categoryName='Accessories' />}/>
          <Route path={ROUTES.THEBOXCONSOLE} render={(props) => <Product {...props} name="The Console Box" categoryRoute={ROUTES.ACCESSORIES} categoryName='Accessories' />}/>
          <Route path={ROUTES.THEBOXCONTROLLER} render={(props) => <Product {...props} name="The Controller Box" categoryRoute={ROUTES.ACCESSORIES} categoryName='Accessories' />}/>
          <Route path={ROUTES.NSCASE} render={(props) => <Product {...props} name="Switch Carry Case" categoryRoute={ROUTES.ACCESSORIES} categoryName='Accessories' />}/>

          <Route path={ROUTES.EDITTEST} render={(props) => <Edit {...props} name="Testing 1 2 3" />}/>

      </Router>
    )
  }
}

export default App;
