import React, { Component } from 'react';
import Img from 'react-image';

import ScrollToTopOnMount from '../../../../ScrollToTopOnMount';

import { getFirebase } from '../../../../firebase';

class CSTournamentDuo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            ready: false
        }
    }

    renderPrice() {
        const amount = String(this.state.data.price[0].amount.toFixed(2));
        let radixPos = amount.indexOf('.');
        let intValue = parseInt(amount);
        let decValue =amount.slice(radixPos + 1);
        return '$' + intValue + '.' + decValue;
    }

    componentDidMount() {
        // DATABASE
        getFirebase()
        .database()
        .ref("/products/0")
        .once("value")
        .then(snapshot => {
            let result = snapshot.val();
            console.log(result);
            this.setState({
                data: result,
                ready: true
            })
        })
    }

    render() {
        if (this.state.ready) {
            return(     
                <div id='cstournamentduo' className='columns is-multiline'>
                    <ScrollToTopOnMount />
                    <div className='column is-full is-mobile hero-container'>
                        {/* HTML Text version */}
                        <div className='text-container has-text-centered'>
                            <h1 className='title is-size-1 is-size-4-mobile'>{this.state.data.name}</h1>
                            <p className='subtitle desc is-size-6'>{this.state.data.subtitle}</p>
                        </div>
                        <div className='img-container'>
                            {/* <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title.jpg", "./img/products/bags/placeholder/title.jpg"]} alt={this.state.data.key} /> */}
                            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" poster="./img/products/bags/cstournamentduo/title-vid-pic.jpg">
                                <source src="./img/products/bags/cstournamentduo/title-vid.mp4" type="video/mp4"></source>
                            </video>
                        </div>
                        {this.state.data.isOnSale ?
                            <div className='price-container has-text-centered'>
                                <span className='price-sale-text'>{this.state.data.price[0].saleText}</span><br/>
                                <p className='price-text is-size-3'>${this.state.data.price[0].saleAmount}</p>
                                <span className='price-st'>${this.state.data.price[0].amount}</span>
                            </div>
                        :
                            <div className='price-container has-text-centered'>
                                {/* <span className='price-sale-text'>{this.state.data.price[0].saleText}</span><br/> */}
                                <p className='price-text normal is-size-3'>${this.state.data.price[0].amount}</p>
                                {/* <span className='price-sale-text'>{this.state.data.price[0].amount}</span> */}
                            </div>
                        }
                    </div>


                    <div className='column is-full is-mobile has-text-centered'>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/bagbanner.jpg", "./img/products/bags/placeholder/title.jpg"]} alt={this.state.data.key} />
                        <h3 className='title is-size-4-mobile'>Carry Up To 5 Pro Controllers</h3>
                        <p className='subtitle desc'>Also compatible with Gamecube Style Controllers.</p>
                        <h3 className='title is-size-4-mobile'>Slots for 4 Joy-con controllers</h3>
                        <p className='subtitle desc'>Foam-fitted nicely for extra protection (it needs it).</p>
                        <h3 className='title is-size-4-mobile'>Space For Switch Console &amp; Official Dock</h3>
                        <p className='subtitle desc'>LAN party on-the-go. Tournament-ready at all times.</p>
                        <h3 className='title is-size-4-mobile'>2 Side Pockets for Accessories</h3>
                        <p className='subtitle desc'>Cables, cables, cables.</p>
                        <h3 className='title is-size-4-mobile'>Pro-grade Fabric &amp; ABS for Ultimate Protection</h3>
                        <p className='subtitle desc'>Pro-level gamer needs Pro-level equipment.</p>
                    </div>

                    <div className='column is-full is-mobile'>
                        
                        <p>CS Tournament Duo is an ultimate gaming console bag made for Nintendo Switch, and also compatible with other gaming platform (PS4 and XBOX One).</p>
                        <br />
                        
                    </div>

                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>Your Whole Gaming Setup In A Bag.</h3>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title.jpg","https://via.placeholder.com/1200x800"]} alt='NSTournamentWater'/>
                    </div>
                    <div className='column is-full is-mobile'>
                        <p>When people carry their console and controllers with just a backpack, sometimes they end up with broken or missing parts or even broken controllers because of carrying them without the right bag to do it. CS Tournament Duo was designed from the start to be a bag that can carry your whole console gaming setup and protect them during transit.</p>
                        <br />
                    </div>

                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>The Box System.</h3>
                        <Img src={["./img/products/bags/cstournamentduo/thebox.jpg","https://via.placeholder.com/1200x800"]} alt='NSTournamentTheBox'/>
                        <p>Tournament Duo comes with 2 "Boxes" that fits into the bag perfectly while being able to hold and protect your console and controllers. Together, it can carry your entire console setup with you just in a single bag while being able to protect from impacts and rain.</p>
                        <br />

                        <div className='columns'>
                            <div className='column is-half has-text-centered'>
                            <Img src={["./img/products/bags/cstournamentduo/box1.jpg","https://via.placeholder.com/720x480"]} alt='NSTournamentBox1'/>
                            <p className='is-size-6'>Box 1: Console Box. It holds your console, prepherials such as Switch Dock, foam-fitted slots for Joy-Cons, and extra space for your contoller.</p>
                            </div>

                            <div className='column is-half has-text-centered'>
                            <Img src={["./img/products/bags/cstournamentduo/box2.jpg","https://via.placeholder.com/720x480"]} alt='NSTournamentBox2'/>
                            <p className='is-size-6'>Box 2: Controller Box. It holds up to 4 full-size controllers, and it is compatible with all popular controller designs (Gamecube, Switch Pro, Xbox One/360, Dualshock, and more!).</p>
                            </div>
                        </div>

                        <p>The Box System is our unique approach on case layouts. Each box comes with preconfigured layout made with strong, interchangeable Velcro dividers. The Console Box comes with a layout that can fit a Switch console, Switch dock, one Full-size controller, and four individual Joy-Con controllers with pre-cut padded foam fittings. The Controller Box comes with 4 equal quadrants that can house four Pro or Gamecube controllers easily. Each box organizes and protects your gaming equipment in the best possible way.</p>
                        <br />
                        <h3 className='title'>The best thing about The Box System is that it is completely interchangeable, so you can fit whatever you want!</h3>
                        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                            <source src="./img/products/bags/cstournamentduo/theboxassembly.mp4" type="video/mp4"></source>
                        </video>
                        <Img src={["./img/products/bags/cstournamentduo/theboxbanner.jpg","https://via.placeholder.com/1200x800"]} alt='NSTournamentTheBox2'/>
                        <div className='spacer-5'></div>
                    </div>
    
                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>Superior Build Quality.</h3>
                        <Img src={["./img/products/bags/cstournamentduo/water.jpg","https://via.placeholder.com/1200x800"]} alt='NSTournamentWater'/>
                    </div>
                    <div className='column is-full is-mobile has-text-centered'>
                        <h3 className='title is-size-4-mobile'>1680D Ballistic Nylon</h3>
                        <p className='subtitle desc'>Weatherproof. Tear resistant. Military-grade.</p>
                        <h3 className='title is-size-4-mobile'>ABS Plastic Hard Shell Frames</h3>
                        <p className='subtitle desc'>Protects sharp impact from all sides.</p>
                        <h3 className='title is-size-4-mobile'>High Quality Double Stitching</h3>
                        <p className='subtitle desc'>Makes the bag stronger &amp; tougher.</p>
                        <h3 className='title is-size-4-mobile'>Inner Poly Foam Padding</h3>
                        <p className='subtitle desc'>Extra-extra-extra protection.</p>
                        <h3 className='title is-size-4-mobile'>Premium Rubberized Handle</h3>
                        <p className='subtitle desc'>Less fatigue on your hands.</p>
                    </div>
                    <div className='column is-full is-mobile'>
                        <p>Our bags are carefully made with premium components and fabrics that are tougher and stronger than other cheap gaming bags on the market. We start with ABS Plastic Hard Shell Frame, made directly from our factory, that is specifically designed to protect your console at all possible angles. We use 1680D Ballistic Nylon as our outer shell, which gives our bag excellent tear and abrasive resistance. We finish our bags with double stitching and premium inner linings. In result, when other bags may last a year or two, our bags can last further beyond while protecting your gear on every trips you make.</p>
                        <br />
                    </div>

                    <div className='column is-10 is-offset-1 has-text-centered'>
                        <h3 className='title is-size-1-desktop'>Specs.</h3>
                        <Img src={["./img/products/bags/cstournamentduo/spec.jpg","https://via.placeholder.com/600x400"]} alt='NSTournamentSpec'/>
                    </div>
                    <div className='column is-10 is-offset-1'>
                        <div className='columns is-variable is-6 is-multiline'>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Tournament Duo Includes</h3>
                                <li className='spec list'>CS Tournament Duo Gaming Console Bag</li>
                                <li className='spec list'>The Console Box</li>
                                <li className='spec list'>The Controller Box</li>
                                <li className='spec list'>Shoulder Strap</li>
                                <li className='spec list'>[BUNDLE EXCLUSIVE] Nintendo Switch Pouch Bag</li>
                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Console Compatibility</h3>
                                <li className='spec list'>Nintendo Switch* with Switch Dock</li>
                                <li className='spec list'>XBOX ONE S/X**</li>
                                <li className='spec list'>PS4 Slim/Pro**</li>
                                <p className='spec dis'>* Nintendo Switch Lite consoles may be supported with some third-party Switch Lite case (sold separately)</p>
                                <p className='spec dis'>** Requires reassembling The Console Box (it's not that hard!)</p>

                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Controller Compatibility</h3>
                                <li className='spec list'>Switch Pro Controller</li>
                                <li className='spec list'>Gamecube-style Controller</li>
                                <li className='spec list'>2 Joy-Cons with Grip*</li>
                                <li className='spec list'>Xbox One Standard/Elite Controller</li>
                                <li className='spec list'>PS4 Dualshock 4 Controller</li>
                                <li className='spec list'>Max Size: 6.5" W x 5.5" L x 2.5" D</li>

                                <p className='spec dis'>* The Console Box can also hold 4 separate Joy-Cons with included foam. So theoretically, you could carry up to 16 individual Joy-Con controllers in one bag...</p>

                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Bag Materials</h3>
                                <li className='spec list'>1680D Ballistic Nylon Outer Shell</li>
                                <li className='spec list'>210D Inner Lining</li>
                                <li className='spec list'>ABS Plastic Inner Frame</li>
                                <li className='spec list'>Poly Foam Cusioning</li>
                                <li className='spec list'>Rubberized Handle</li>
                                <li className='spec list'>Custom-designed Zippers</li>
                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Dimensions &amp; Weight</h3>
                                <li className='spec list'>Bag Dimensions: 16" W x 12" H x 8" D</li>
                                <li className='spec list'>Inner Dimensions: 15" W x 12" H x 7" D</li>
                                <li className='spec list'>Weight: 6.5 lbs (3 lbs without The Boxes)</li>
                                <li className='spec list'>The Box Dimensions: 14" W x 11" H x 2.5" D</li>
                            </div>
                        </div>
                    </div>

                    <div className='column is-10 is-offset-1 has-text-centered'>
                        <Img src={["./img/products/bags/cstournamentduo/colors.jpg","https://via.placeholder.com/600x400"]} alt='NSTournamentSpec'/>
                        <h3 className='title is-4 has-text-grey-lighter'>Available In <span className='red'>Red</span> &amp; <span className='black'>Black</span></h3>
                        <a class="button is-info has-text-weight-bold" href="https://www.mcycustomcase.com/shop?category=gaming">Buy Now</a>
                    </div>
                </div>
                
            );
        } else {
            return(
                <div>Loading...</div>
            )
        }
        
    }
}

export default CSTournamentDuo;