import React, { Component } from 'react';
import Img from 'react-image';

class About extends Component {

    render() {
        return(
            <div id='about' className='section'>
                <div className='container'>
                    <div className='columns is-multiline'>
                        <div className='column is-full'>
                            <Img src="./img/hero.jpg" alt='GAMING'/>
                        </div>
                        <div className='column is-full'>
                        <h1 className='title'>Bring Your Gaming To Upper Case.</h1>
                        <p className=''>Don’t get your gaming equipment to become a burden. No more carrying your controllers and console on a cheap backpack. Uppercase Gaming brings your gaming lifestyle to the next level.
                        <br /><br />
                        We are Chicago-based bags &amp; cases manufacturer with more than 25 years of manufacturing custom-made bags and cases. We created Uppercase Gaming because we want to bring our expertise of making high-end custom bags into creating bags &amp; cases designed for serious gamers. Every bag we make is made to last and protect your gear even on the toughest environment. If you are looking for an ultimate gaming bag, you've come to the right place.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;