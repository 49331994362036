import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Img from 'react-image';

import * as ROUTES from '../../../constants/routes';
import { getFirebase } from '../../../firebase';




class Accessories extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accessories: [],
            list: [],
            ready: false,
            tag: 'showall'
        }
    }

    componentDidMount() {
        // DATABASE
        getFirebase()
        .database()
        .ref("/accessories")
        .once("value")
        .then(snapshot => {
            let result = snapshot.val();
            console.log(result);
            this.setState({
                accessories: result,
                list: result,
                ready: true
            })
        })
    }

    render() {
        return(
            <div id='accessories' className='section'>
                <div className='container'>
                    <div className='columns is-multiline'>
                        {/* <div className='column is-full'>
                            <Img src="https://via.placeholder.com/1200x400" alt='GAMING'/>
                        </div> */}
                        <div className='column is-full'>
                            <h2 className='title is-size-4'>Accessories</h2>
                        </div>
                        {this.state.ready ?
                          <div className='column is-full'>
                            {/* <div class="buttons">
                                <button class={("button is-small is-info has-text-weight-bold ") + (this.state.tag === 'Console' ? "" : "is-outlined")} onClick={this.filterTags.bind(this, "Console")}>Console Gaming</button>
                                <button class={("button is-small is-success has-text-weight-bold ") + (this.state.tag === 'TCG' ? "" : "is-outlined")} onClick={this.filterTags.bind(this, "TCG")} >Trading Card Games</button>
                                <button class={("button is-small is-light has-text-weight-bold ") + (this.state.tag === 'showall' ? "" : "is-outlined")} onClick={this.filterTags.bind(this, "showall")} >Show All</button>
                            </div> */}
                            <p className='is-size-6'>Showing {this.state.list.length} Products(s).</p>
                          </div>
                        : <div className='column is-full'>
                            <p className='is-size-6 mb-1'>Loading Data...</p>
                            <progress class="progress is-danger" max="100">30%</progress>
                          </div>}
                    </div>
                    <div className='columns is-mobile is-multiline is-variable is-1'>
                        {this.state.list.length > 0 ?
                            this.state.list.map((item) => {
                                // console.log(item.category);
                                const amount = String(item.price[0].amount.toFixed(2));
                                let radixPos = amount.indexOf('.');
                                let intValue = parseInt(amount);
                                let decValue =amount.slice(radixPos + 1);
                                let source = "./img/products/"+item.category+"/"+item.key+"/thumbnail.jpg";
                                return(
                                    <div className='column is-full-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd'>
                                        <div className='product-box'>
                                        <Link to={"./" + item.key}>
                                        {/* <div className='product-list'>
                                            <div className='product-list-img'>
                                                    <Img src={["./img/products/"+item.category+"/"+item.key+"/thumbnail.jpg", "./img/products/bags/placeholder/thumbnail.jpg"]} alt={item.key} />
                                            </div>
                                        </div> */}
                                        <div>
                                            <Img src={["./img/products/"+item.category+"/"+item.key+"/thumbnail.jpg", "./img/products/bags/placeholder/thumbnail.jpg"]} alt={item.key} />
                                            <span className='price-text'>${intValue}.<span className='price-cents'>{decValue}</span></span>
                                        </div>
                                        
                                        </Link>
                                        
                                        </div>
                                    </div>
                                )
                            })
                        : null }
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Accessories;