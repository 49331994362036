import React, { Component } from 'react';
import Img from 'react-image';

import ScrollToTopOnMount from '../../../../ScrollToTopOnMount';

import { getFirebase } from '../../../../firebase';

class CSTournamentSolo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            ready: false
        }
    }

    renderPrice() {
        const amount = String(this.state.data.price[0].amount.toFixed(2));
        let radixPos = amount.indexOf('.');
        let intValue = parseInt(amount);
        let decValue =amount.slice(radixPos + 1);
        return '$' + intValue + '.' + decValue;
    }

    componentDidMount() {
        // DATABASE
        getFirebase()
        .database()
        .ref("/products/1")
        .once("value")
        .then(snapshot => {
            let result = snapshot.val();
            console.log(result);
            this.setState({
                data: result,
                ready: true
            })
        })
    }

    render() {
        if (this.state.ready) {
            return(     
                <div id='cstournamentsolo' className='columns is-multiline'>
                    <ScrollToTopOnMount />
                    <div className='column is-full is-mobile hero-container'>
                        {/* HTML Text version */}
                        <div className='text-container has-text-centered'>
                            <h1 className='title is-size-1 is-size-4-mobile'>{this.state.data.name}</h1>
                            <p className='subtitle desc is-size-6'>{this.state.data.subtitle}</p>
                            {/* <p className='price is-size-3'>{this.renderPrice()}<br/></p> */}
                        </div>
                        <div className='img-container'>
                            {/* <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title.jpg", "./img/products/bags/placeholder/title.jpg"]} alt={this.state.data.key} /> */}
                            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" poster={"./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title-vid-pic.jpg"}>
                                <source src={"./img/products/"+this.state.data.category+"/"+this.state.data.key+"/title-vid.mp4"} type="video/mp4"></source>
                            </video>
                        </div>
                        {this.state.data.isOnSale ?
                            <div className='price-container has-text-centered'>
                                <span className='price-sale-text'>{this.state.data.price[0].saleText}</span><br/>
                                <p className='price-text is-size-3'>${this.state.data.price[0].saleAmount}</p>
                                <span className='price-st'>${this.state.data.price[0].amount}</span>
                            </div>
                        :
                            <div className='price-container has-text-centered'>
                                {/* <span className='price-sale-text'>{this.state.data.price[0].saleText}</span><br/> */}
                                <p className='price-text normal is-size-3'>${this.state.data.price[0].amount}</p>
                                {/* <span className='price-sale-text'>{this.state.data.price[0].amount}</span> */}
                            </div>
                        }
                    </div>

                    <div className='column is-full is-mobile has-text-centered'>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/bagbanner.jpg", "./img/products/bags/placeholder/title.jpg"]} alt={this.state.data.key} />

                        
                        <h3 className='title is-size-4-mobile'>Made For Nintendo Switch</h3>
                        <p className='subtitle desc'>With Console Case included.</p>
                        <h3 className='title is-size-4-mobile'>Slots for 4 Joy-cons and a Pro Controller</h3>
                        <p className='subtitle desc'>Theoretically, it can fit up to EIGHT Joy-Cons!</p>
                        <h3 className='title is-size-4-mobile'>Space For Switch Console &amp; Official Dock</h3>
                        <p className='subtitle desc'>Carry your whole system with one bag.</p>
                        <h3 className='title is-size-4-mobile'>Detachable, Fully-customizable Layout</h3>
                        <p className='subtitle desc'>Change it however you want!</p>
                        <h3 className='title is-size-4-mobile'>Pro-grade Fabric &amp; ABS for Ultimate Protection</h3>
                        <p className='subtitle desc'>Pro-level gamer needs Pro-level equipment.</p>
                    </div>

                    <div className='column is-full is-mobile'>
                        
                        <p>CS Tournament Solo is designed for serious gamers who are looking for gaming bag that can carry &amp; protect your Nintendo Switch.</p>
                        <br />
                        
                    </div>

                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>Compact Gaming Suitcase.</h3>

                        <div className='columns is-variable is-1 is-multiline'>
                            <div className="column is-half">
                            <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/shot.jpg","https://via.placeholder.com/1200x800"]} alt='CSTournamentSolo'/>
                            </div>
                            <div className="column is-half">
                            <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/setup.jpg","https://via.placeholder.com/1200x800"]} alt='CSTournamentSolo'/>
                            </div>
                        </div>
                    </div>
                    <div className='column is-full is-mobile'>
                        <p>Super-compact design lets you carry your whole Nintendo Switch setup, including your Switch Dock and a Pro Controller, in a single bag. Unlike bulky design of gaming bags on the market, Tournament Solo's suitcase-like design allows you to carry your bag easily to all places.</p>
                        <br />
                    </div>

                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>Open Up. It's A Box.</h3>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/openbox.jpg","https://via.placeholder.com/1200x800"]} alt='CSTournamentSolo'/>
                    </div>
                    <div className='column is-full is-mobile'>
                        <p>Tournament Solo comes with "The Box", our creative and intuitive way of enabling flexible layout for a gaming bag. The Box in Tournament Solo comes in a console setup specifically designed for Nintendo Switch. It can fit your Switch console, official Switch Dock, 4 separate Joy-Cons, and a full-size Pro or Gamecube-style Controller. It is very easy to modify the layout thanks to its Velcro-based dividers, so you can fit virtually anything you want!
                        </p>
                        <br />
                        <div className='columns is-multiline'>
                            <div className='column is-half has-text-centered'>
                            <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/boxout.jpg","https://via.placeholder.com/720x480"]} alt='CSTournamentSolo'/>
                            <p className='is-size-6'>Don't need "The Box"? You can take it out, and use the space for anything you want, such as laptops or other every carry items! (It's actually a suitcase now)</p>
                            </div>

                            <div className='column is-half has-text-centered'>
                            <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/controllerbox.jpg","https://via.placeholder.com/720x480"]} alt='CSTournamentSolo'/>
                            <p className='is-size-6'>Have a lot of controllers? You can fit The Controller Box (sold separately) instead, and make this bag a Controller Suitcase!</p>
                            </div>
                        </div>
                        <br />
                    </div>

                    <div className='column is-full is-mobile'>
                        <h3 className='title is-size-1-desktop has-text-centered'>Not Your Average Gaming Bag.</h3>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/water.jpg","https://via.placeholder.com/1200x800"]} alt='CSTournamentSolo'/>
                    </div>
                    <div className='column is-full is-mobile has-text-centered'>
                        <h3 className='title is-size-4-mobile'>1680D Ballistic Nylon</h3>
                        <p className='subtitle desc'>Weatherproof. Tear resistant. Military-grade.</p>
                        <h3 className='title is-size-4-mobile'>ABS Plastic Hard Shell Frames</h3>
                        <p className='subtitle desc'>Protects sharp impact from all sides.</p>
                        <h3 className='title is-size-4-mobile'>High Quality Double Stitching</h3>
                        <p className='subtitle desc'>Makes the bag stronger &amp; tougher.</p>
                        <h3 className='title is-size-4-mobile'>Inner Poly Foam Padding</h3>
                        <p className='subtitle desc'>Extra-extra-extra protection.</p>
                        <h3 className='title is-size-4-mobile'>Premium Rubberized Handle</h3>
                        <p className='subtitle desc'>Less fatigue on your hands.</p>
                    </div>
                    <div className='column is-full is-mobile'>
                        <p>If you take your console seriously, then this bag is for you, because it is designed and made with high quality materials that surpasses other gaming bags on the market. It is weatherproof and tear resistant all throughout the bag, and it comes with superior impact protection with ABS plastic hard shell frames inside protecting your console. In combination, Tournament Solo combines the strong protection of hard case bags with a flexibility of a soft case bag. In other words, it is perfect.</p>
                        <br />
                    </div>

                    <div className='column is-10 is-offset-1 has-text-centered'>
                        <h3 className='title is-size-1-desktop'>Specs.</h3>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/spec.jpg","https://via.placeholder.com/600x400"]} alt='CSTournamentSolo'/>
                    </div>
                    <div className='column is-10 is-offset-1'>
                        <div className='columns is-variable is-6 is-multiline'>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Tournament Solo Includes</h3>
                                <li className='spec list'>CS Tournament Solo Gaming Console Bag</li>
                                <li className='spec list'>The Console Box</li>
                                <li className='spec list'>Shoulder Strap</li>
                                <li className='spec list'>[BUNDLE EXCLUSIVE] Nintendo Switch Pouch Bag</li>
                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Console Compatibility</h3>
                                <li className='spec list'>Nintendo Switch* with Switch Dock</li>
                                <p className='spec dis'>* Nintendo Switch Lite consoles may be supported with some third-party Switch Lite case (sold separately)</p>
                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Controller Compatibility</h3>
                                <li className='spec list'>Switch Pro Controller</li>
                                <li className='spec list'>Gamecube-style Controller</li>
                                <li className='spec list'>2 Joy-Cons with Grip*</li>
                                <li className='spec list'>Max Size: 6.5" W x 5.5" L x 2.5" D</li>

                                <p className='spec dis'>* Theoretically, you could carry up to 8 individual Joy-Con controllers with this bag!</p>

                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Bag Materials</h3>
                                <li className='spec list'>1680D Ballistic Nylon Outer Shell</li>
                                <li className='spec list'>210D Inner Lining</li>
                                <li className='spec list'>ABS Plastic Inner Frame</li>
                                <li className='spec list'>Poly Foam Cusioning</li>
                                <li className='spec list'>Rubberized Handle</li>
                                <li className='spec list'>Custom-designed Zippers</li>
                            </div>
                            <div className='column is-6'>
                                <h3 className='title spec is-6'>Dimensions &amp; Weight</h3>
                                <li className='spec list'>Bag Dimensions: 15" W x 12" H x 4" D</li>
                                <li className='spec list'>Inner Dimensions: 15" W x 12" H x 3" D</li>
                                <li className='spec list'>Weight: 4 lbs (2 lbs without The Boxes)</li>
                                <li className='spec list'>The Box Dimensions: 14" W x 11" H x 2.5" D</li>
                            </div>
                        </div>
                    </div>

                    <div className='column is-10 is-offset-1 has-text-centered'>
                        <Img src={["./img/products/"+this.state.data.category+"/"+this.state.data.key+"/color.jpg","https://via.placeholder.com/1280x540"]} alt='NSTournamentSpec'/>
                        <h3 className='title is-4 has-text-grey-lighter'>Available In <span className='red'>Red</span> &amp; <span className='black'>Black</span></h3>
                        <a class="button is-info has-text-weight-bold" href="https://www.mcycustomcase.com/shop?category=gaming">Buy Now</a>
                    </div>
                </div>
                
            );
        } else {
            return(
                <div>Loading...</div>
            )
        }
        
    }
}

export default CSTournamentSolo;