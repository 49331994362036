export const LANDING = '/';
export const HOME = '/home';

export const ABOUT = '/#about';
export const BAGS = '/#bags';
export const ACCESSORIES = '/#accessories';
export const CUSTOM = '/#custom';
export const CONTACT = '/#inquiries';

export const CSTOURNAMENTDUO = '/cstournamentduo';
export const CSTOURNAMENTSOLO = '/cstournamentsolo';
export const TCGTRAVELERDUO = '/tcgtravelerduo';
export const TCGTRAVELERSOLO = '/tcgtravelersolo';

export const SHOULDERSTRAP01 = '/shoulderstrap-01';
export const THEBOXCONSOLE = '/theboxconsole';
export const THEBOXCONTROLLER = '/theboxcontroller';
export const NSCASE = '/nscase';



export const EDITTEST = '/edittest';