import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Img from 'react-image';

// import NSTournament from '../../Products/Bags/NSTournament';

import './index.css';

import { getFirebase } from '../../../firebase';


class Bags extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            list: [],
            ready: false,
            tag: 'showall'
        }
    }

    componentDidMount() {
        // DATABASE
        getFirebase()
        .database()
        .ref("/products")
        .once("value")
        .then(snapshot => {
            let result = snapshot.val();
            console.log(result);
            this.setState({
                products: result,
                list: result,
                ready: true
            })
        })
    }

    filterTags(tag) {
        if (tag === "showall") {
            this.setState({
                tag: 'showall',
                list: this.state.products
            })
        } else {
            let prod = this.state.products;
            let result = prod.filter((item) => item.tag.includes(tag))
            this.setState({
                tag: tag,
                list: result
            })
        }
    }

    render() {
        return(
            <div id='bags' className='section'>
                <div className='container'>
                    <div className='columns is-multiline'>
                        {/* <div className='column is-full'>
                            <Img src="https://via.placeholder.com/1200x400" alt='GAMING'/>
                        </div> */}
                        <div className='column is-full'>
                            <h2 className='title is-size-4'>Bags &amp; Cases</h2>
                        </div>
                        {this.state.ready ?
                          <div className='column is-full'>
                            <div class="buttons">
                                <button class={("button is-small is-info has-text-weight-bold ") + (this.state.tag === 'Console' ? "" : "is-outlined")} onClick={this.filterTags.bind(this, "Console")}>Console Gaming</button>
                                <button class={("button is-small is-success has-text-weight-bold ") + (this.state.tag === 'TCG' ? "" : "is-outlined")} onClick={this.filterTags.bind(this, "TCG")} >Trading Card Games</button>
                                <button class={("button is-small is-light has-text-weight-bold ") + (this.state.tag === 'showall' ? "" : "is-outlined")} onClick={this.filterTags.bind(this, "showall")} >Show All</button>
                            </div>
                            <p className='is-size-6'>Showing {this.state.list.length} Products(s).</p>
                          </div>
                        : <div className='column is-full'>
                            <p className='is-size-6 mb-1'>Loading Data!</p>
                            <progress class="progress is-danger" max="100">30%</progress>
                          </div>}
                    </div>
                    <div className='columns is-mobile is-multiline is-variable is-1'>
                        {this.state.list.length > 0 ?
                            this.state.list.map((item) => {
                                // console.log(item.category);
                                let amount = String(item.price[0].amount.toFixed(2));

                                if (item.isOnSale) {
                                    amount = String(item.price[0].saleAmount.toFixed(2));
                                }

                                let radixPos = amount.indexOf('.');
                                let intValue = parseInt(amount);
                                let decValue =amount.slice(radixPos + 1);
                                return(
                                    <div className='column is-full-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd'>
                                        <div className='product-box'>
                                        <Link to={"./" + item.key}>
                                        {/* <div className='product-list'>
                                            <div className='product-list-img'>
                                                    <Img src={["./img/products/"+item.category+"/"+item.key+"/thumbnail.jpg", "./img/products/bags/placeholder/thumbnail.jpg"]} alt={item.key} />
                                            </div>
                                        </div> */}
                                        <div>
                                            <Img src={["./img/products/"+item.category+"/"+item.key+"/thumbnail.jpg", "./img/products/bags/placeholder/thumbnail.jpg"]} alt={item.key} />
                                            {item.isOnSale ? 
                                                <span className='price-text'>${intValue}.<span className='price-cents'>{decValue}</span> <span className='price-text st'>${item.price[0].amount}</span></span>
                                            :
                                                <span className='price-text'>${intValue}.<span className='price-cents'>{decValue}</span></span>
                                            }

                                            {item.isOnSale ?
                                                <span className='price-sale-text'>{item.price[0].saleText}</span>
                                            :
                                                null
                                            }
                                            
                                        </div>
                                        
                                        </Link>
                                        
                                        </div>
                                    </div>
                                )
                            })
                        : null }
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Bags;