import React, { Component } from 'react';
import Img from 'react-image';

import './index.css';

class Custom extends Component {

    render() {
        return(
            <div id='custom' className='section'>
                <div className='container'>
                    <div className='columns is-multiline'>
                        <div className='column is-full'>
                            <Img src="./img/custom.jpg" alt='Custom Bags'/>
                        </div>
                        <div className='column is-full'>
                        <h1 className='title'>We Can Also Make Custom-Designed Bags For You.</h1>
                        <p className='is-size-5'>Do you need custom-designed gaming bags &amp; cases for your esports team? Have specific use cases? Need a cool looking bag made just for your brand? We can do them all. We have professional bag &amp; cases designers on site that can work with you at all times. We have on-site manufacturing facility that can quicky prototype samples and design bags that fits your requirements.</p>
                        <br />
                        <p className='is-size-5'>Why should you trust us? Well, because we know how to make bags &amp; cases. We have designed and produced bags &amp; cases of all type of sizes and use cases. If you have something in mind, we probably have a solution already. Not only that, we can make wide range of products, from affordable options to full-on military-grade level of protective cases. We can make that gaming bag of your dreams come to life.</p>
                        <br />
                        </div>
                    </div>
                    <div className='columns is-mobile is-multiline is-variable is-1'>
                        <div className='column is-half-mobile is-half-tablet is-one-quarter-desktop custom-box'>
                            <Img src={["./img/customcases/gamingevents.jpg", "./img/missingno/1200x800.png"]} alt='gaming event bags'/>
                        </div>
                        <div className='column is-half-mobile is-half-tablet is-one-quarter-desktop custom-box'>
                            <Img src={["./img/customcases/custombags.jpg", "https://via.placeholder.com/1200x800"]} alt='custom-fit bags and cases'/>
                        </div>
                        <div className='column is-half-mobile is-half-tablet is-one-quarter-desktop custom-box'>
                            <Img src={["./img/customcases/lasercut.jpg", "https://via.placeholder.com/1200x800"]} alt='laser-cut products'/>
                        </div>
                        <div className='column is-half-mobile is-half-tablet is-one-quarter-desktop custom-box'>
                            <Img src={["./img/customcases/embrodiary.jpg", "https://via.placeholder.com/1200x800"]} alt='embrodiary brand logos'/>
                        </div>
                        
                    </div>
                    
                    <div className='columns is-multiline'>
                        <div className='column is-full has-text-centered'>
                            <h3 className='title is-size-5-mobile'>Gaming Events Goodies</h3>
                            <p className='subtitle desc is-size-6-mobile'>Gift bags, Event prizes &amp; merch, with custom brand logo.</p>
                            <h3 className='title is-size-5-mobile'>Esports Team Branded Merch</h3>
                            <p className='subtitle desc is-size-6-mobile'>For your players and your fans.</p>
                            <h3 className='title is-size-5-mobile'>Made-to-order Custom Bags</h3>
                            <p className='subtitle desc is-size-6-mobile'>From Gaming Laptops to 55-inch 4K TVs. We can do all.</p>
                            <h3 className='title is-size-5-mobile'>On-site Laser-cutting Machinery</h3>
                            <p className='subtitle desc is-size-6-mobile'>Cutting &amp; Engraving on acryllic &amp; metal.</p>
                            <h3 className='title is-size-5-mobile'>Logo Embrodiary Services</h3>
                            <p className='subtitle desc is-size-6-mobile'>Need your logo on bags? We can do it!</p>
                        </div>
                        <div className='column is-full'>
                            <p className='mb-1'>Ready to show off your ideas to us? Have some questions about what we can do? We can help you out ASAP!</p>
                            <a class="button is-info has-text-weight-bold" href="mailto:info@mcycustomcase.com?subject=Custom%20Bag%20Inquiry">Get Started!</a>  
                            {/* <button class="button is-info has-text-weight-bold">Request Now!</button> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Custom;