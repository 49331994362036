import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
// import Img from 'react-image';

import * as ROUTES from '../../constants/routes.js';

import './Breadcrumb.css';

class Breadcrumb extends Component {
    render() {
        return(
            <nav className="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li><Link to={ROUTES.HOME}>Home</Link></li>
                    {   this.props.history.length > 0 ? 
                        this.props.history.map((i) => {
                            return(
                                <li><Link to={i.route}>{i.name}</Link></li>
                            )
                        })
                        : null
                    }
                    <li class="is-active"><Link to={this.props.current.route !== undefined ? this.props.current.route : ROUTES.HOME} aria-current="page">{this.props.current.name !== undefined ? this.props.current.name : "Home"}</Link></li>
                </ul>
            </nav>
        )
    }
}

export default Breadcrumb;