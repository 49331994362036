import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import Img from 'react-image';

import Scrollspy from 'react-scrollspy';

import * as ROUTES from '../../constants/routes.js';

import './Navigation.css';

class Navigation extends Component {

    scrollWithOffset(el) {
        const yCoord = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = 0;
        window.scrollTo({top: yCoord + yOffset, behavior: 'smooth'});
    }

    render() {
        return(
            <div>
            <div className='uc-nav-mask'></div>
            <div className='uc-nav'>
                <Img src={[
                "./logo_web.png",
                "https://via.placeholder.com/600x300"]} 
                alt='UPPER_CASE GAMING'/>
                <nav className='categories'>
                    <Scrollspy items={['about', 'bags', 'accessories', 'custom', 'inquiries']} currentClassName='is-current' offset={ -64 }>
                    <div className='category'>
                        <NavLink 
                            to={ROUTES.ABOUT} 
                            id='#about' 
                            className='category-link' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                            About Us
                        </ NavLink><div className='uc-dot'></div>
                    </div>
                    <div className='category'>
                        <NavLink 
                            to={ROUTES.BAGS} 
                            id='#bags' 
                            className='category-link' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                            Bags &amp; Cases
                        </ NavLink><div className='uc-dot'></div>
                    </div>
                    <div className='category'>
                        <NavLink 
                            to={ROUTES.ACCESSORIES} 
                            id='#accessories'  
                            className='category-link' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                            Accessories
                        </ NavLink><div className='uc-dot'></div>
                    </div>
                    <div className='category'>
                        <NavLink 
                            to={ROUTES.CUSTOM} 
                            id='#custom' 
                            className='category-link' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                            Custom Cases
                        </ NavLink><div className='uc-dot'></div>
                    </div>
                    <div className='category'>
                        <NavLink 
                            to={ROUTES.CONTACT} 
                            id='#inquiries' 
                            className='category-link' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                            Inquiries &amp; Contact
                        </ NavLink><div className='uc-dot'></div>
                    </div>
                    </Scrollspy>
                </nav>
            </div>
            </div>
            
        )
    }
}

export default Navigation;