import firebase from "firebase/app";
import database from "firebase/database";

const config = {
    apiKey: "AIzaSyA4kjZXq0SIioCMyHJEkDNqlsWzK6aO5vw",
    authDomain: "uppercasegaming-e4ff3.firebaseapp.com",
    databaseURL: "https://uppercasegaming-e4ff3.firebaseio.com",
    projectId: "uppercasegaming-e4ff3",
    storageBucket: "uppercasegaming-e4ff3.appspot.com",
    messagingSenderId: "466331239357",
    appId: "1:466331239357:web:11999e73bb04538d3608d4"
};

let firebaseCache;

export const getFirebase = () => {
    if (firebaseCache) {
        return firebaseCache;
    }

    firebase.initializeApp(config);
    firebaseCache = firebase;
    return firebase;
};

export const generateDate = () => {
    const now = new Date();
    const options = { month: "long", day: "numeric", year: "numeric" };
  
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`; // prepend with a 0
    }
  
    const day = now.getDate();
    if (day < 10) {
      day = `0${day}`; // prepend with a 0
    }
  
    return {
      formatted: `${year}-${month}-${day}`,             // used for sorting
      pretty: now.toLocaleDateString("en-US", options)  // used for displaying
    };
};
  