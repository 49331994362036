import React, { Component, useRef } from 'react';
import { NavHashLink as NavLink, HashLink as Link } from 'react-router-hash-link';

import Img from 'react-image';

import Scrollspy from 'react-scrollspy';

import * as ROUTES from '../../constants/routes.js';

import './NavigationBar.css';

class NavigationBar extends Component {

    untoggleBurger() {
        let burger = document.getElementById("navBurg");
        let menu = document.getElementById("narbarToggle");
        burger.classList.remove('is-active');
        menu.classList.remove('is-active');
    }

    toggleBurger() {
        let burger = document.getElementById("navBurg");
        let menu = document.getElementById("narbarToggle");
        burger.classList.toggle('is-active');
        menu.classList.toggle('is-active');
    }

    scrollWithOffset(el) {
        const yCoord = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -64;
        window.scrollTo({top: yCoord + yOffset, behavior: 'smooth'});
    }

    render() {
        return(
            <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">

                <div className="navbar-brand">
                <div className="navbar-logo">
                    <Link to={ROUTES.HOME}>
                        <figure class="image is-32x32">
                            <Img 
                            src="./logo_circle.png" 
                            alt='UPPER_CASE GAMING'/>
                        </figure>
                    </Link>
                </div>
                {/* data-target="narbarToggle" */}
                <button id="navBurg" className="navbar-burger burger" aria-label="menu" aria-expanded="false" onClick={this.toggleBurger.bind(this)}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
                </div>
    
                <div id="narbarToggle" className="navbar-menu">
        
                <div className="navbar-end">
                    <div className='navbar-item logo-text'>
                    <span className='has-text-weight-black'>UPPER<span className='red'>_</span>CASE GAMING </span><br /><span className='has-text-family-campaign is-size-6'>by MCY Custom Case</span>
                    </div>
                        <NavLink 
                            to={ROUTES.ABOUT} 
                            id="#about" 
                            className='navbar-item' 
                            activeClassName="is-current" 
                            scroll={this.scrollWithOffset}
                        >
                                About Us
                        </NavLink>
                        <NavLink 
                            to={ROUTES.BAGS} 
                            id="#bags" 
                            className='navbar-item' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                                Bags &amp; Cases
                        </NavLink>
                        <NavLink 
                            to={ROUTES.ACCESSORIES} 
                            id="#accessories" 
                            className='navbar-item' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                                Accessories
                        </NavLink>
                        <NavLink 
                            to={ROUTES.CUSTOM} 
                            id="#custom" 
                            className='navbar-item' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                                Custom Cases
                        </NavLink>
                        <NavLink 
                            to={ROUTES.CONTACT} 
                            id="#inquiries" 
                            className='navbar-item' 
                            activeClassName="is-current"
                            scroll={this.scrollWithOffset}
                        >
                                Inquiries &amp; Contact
                        </NavLink>
                </div>
                </div>
          </nav>
        )
    }
}

export default NavigationBar;