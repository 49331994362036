import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Img from 'react-image';

import {
    BrowserRouter as Router,
    Route,
  } from 'react-router-dom';

import * as ROUTES from '../../constants/routes.js';

import Navigation from '../../components/Navigation';
import NavigationBar from '../../components/NavigationBar';

import Footer from '../../components/Footer';

import Breadcrumb from '../../components/Breadcrumb';

import CSTournamentDuo from '../../components/Products/Bags/cstournamentduo';
import CSTournamentSolo from '../../components/Products/Bags/cstournamentsolo';
import TCGTravelerSolo from '../../components/Products/Bags/tcgtravelersolo';
import TCGTravelerDuo from '../../components/Products/Bags/tcgtravelerduo/index.js';

import ShoulderStrap01 from '../../components/Products/Accessories/ShoulderStrap01';
import TheBoxConsole from '../../components/Products/Accessories/TheBoxConsole';
import TheBoxController from '../../components/Products/Accessories/TheBoxController';
import NSCase from '../../components/Products/Accessories/NSCase/index.js';

class Product extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let ti = this.props.name ? this.props.name : "";
        document.title = ti + " :: UPPER_CASE GAMING";
    }

    render() {
        return(
                <main class="uc-main">
                    <div className='is-hidden-desktop'>
                        <NavigationBar />
                    </div>
                    <div className='uc-side-background is-hidden-touch'></div>
                    <div className='uc-container container'>
                        <div className='uc-duo'>
                            
                            <aside className='uc-side menu is-hidden-touch'>
                                <Navigation />
                            </aside>

                            <div className='uc-lead'>
                                
                                <div className='section'>
                                    <Breadcrumb 
                                        history={[
                                            {
                                                route: this.props.categoryRoute,
                                                name: this.props.categoryName
                                            }
                                        ]}
                                        current={{
                                            route: this.props.location.pathname,
                                            name: this.props.name
                                        }}
                                    />
                                    <div className="container">
                                        <Route path={ROUTES.CSTOURNAMENTDUO} render={(props) => <CSTournamentDuo {...props} /> } />
                                        <Route path={ROUTES.CSTOURNAMENTSOLO} render={(props) => <CSTournamentSolo {...props} /> } />
                                        <Route path={ROUTES.TCGTRAVELERSOLO} render={(props) => <TCGTravelerSolo {...props} /> } />
                                        <Route path={ROUTES.TCGTRAVELERDUO} render={(props) => <TCGTravelerDuo {...props} /> } />

                                        <Route path={ROUTES.SHOULDERSTRAP01} render={(props) => <ShoulderStrap01 {...props} /> } />
                                        <Route path={ROUTES.THEBOXCONSOLE} render={(props) => <TheBoxConsole {...props} /> } />
                                        <Route path={ROUTES.THEBOXCONTROLLER} render={(props) => <TheBoxController {...props} /> } />
                                        <Route path={ROUTES.NSCASE} render={(props) => <NSCase {...props} /> } />



                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </main>
        )
    }
}

export default Product;